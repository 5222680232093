<template>
  <div>
    <Table
      label="Exported Data"
      :actions="actions"
      :items="$store.getters['exportResult/tableData']"
      :fields="$store.getters['exportResult/tableFields']"
      :loading="$store.state.exportResult.loadingTable"
      :filter="$store.state.exportResult.tableFilter"
      :tab-options="tabOptions"
      :sort="$store.state.exportResult.tableSort"
      :sort-options="sortOptions"
      :keyword="$store.state.exportResult.tableKeyword"
      :pagination="$store.state.exportResult.tablePagination"
      @changeFields="changeFields"
      @resetFields="resetFields"
      @filter="filterData"
      @sort="sortData"
      @load="loadData"
      @paginate="paginate"
      @search="search"
    />
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'

export default {
  components: {
    Table,
  },
  data() {
    return {
      // Sort
      sortOptions: [
        {
          key: 'createdAt', direction: 'desc', label: 'Newest', default: true,
        },
        { key: 'createdAt', direction: 'asc', label: 'Oldest' },
      ],

      // Tabs
      tabOptions: [
        // do not put value: undefined
        // because {..., value: undefined} is not equal {...}
        {
          key: 'status', label: 'All', default: true,
        },
        {
          key: 'status', label: 'Pending', value: 'PENDING',
        },
        {
          key: 'status', label: 'Succeeded', value: 'SUCCEEDED',
        },
        {
          key: 'status', label: 'Failed', value: 'FAILED',
        },
      ],

      actions: ['view', {
        type: 'other',
        children: [
          {
            label: 'Download',
            event: this.download,
            visibility: {
              callback: data => data.url,
            },
          },
        ],
      }],
    }
  },
  methods: {
    loadData() {
      return this.$store.dispatch('exportResult/getTableData')
    },
    filterData(val) {
      this.$store.commit('exportResult/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('exportResult/SET_TABLE_SORT', val)
    },
    paginate(val) {
      this.$store.commit('exportResult/SET_TABLE_PAGINATION', val)
    },
    search(val) {
      this.$store.commit('exportResult/SET_TABLE_KEYWORD', val)
    },
    changeFields(val) {
      this.$store.commit('exportResult/SET_TABLE_FIELDS', val)
    },
    resetFields() {
      this.$store.commit('exportResult/RESET_TABLE_FIELDS')
    },
    download(data) {
      fetch(data.item.url)
        .then(resp => resp.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = url
          // the filename you want
          a.download = data.item.file_name
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        })
        .catch(() => {})
    },
  },
}
</script>
